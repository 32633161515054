export enum ApiParameter {
	ADVERTISEMENT_ID = 'advertisementId',
	DIRECTORY = 'directory',
	DIRECTORY_ID = 'directoryId',
	EDITING = 'editing',
	FILE_ID = 'fileId',
	NAME = 'name',
	PAGE = 'page',
	PARENT_ID = 'parentId',
	PRINT_JOB_ID = 'printJobId',
	PROJECT_ID = 'projectId',
	SEARCH = 'search',
	SIZE = 'size',
	SORT = 'sort',
	TYPE = 'type',
	USER_ID = 'userId',
	USER_ROLE_ID = 'userRoleId',
	USER_ROLE_NAME = 'userRoleName',
	VISIBLE = 'visible',
}
